/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.forgetPasswordForm {
    width: 480px;
  }
  
  @media (max-width: 768px) {
    .forgetPasswordForm {
      width: 300px; 
    }
  }
  
  @media (min-width: 769px) {
    .forgetPasswordForm {
      width: 480px; 
    }
  }