.smallfont {
  font-size: 13px;
}
.blue {
  color: rgb(65, 65, 255) !important;
}
.singup_container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.selectField {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.selectField:disabled {
  background-color: #e0e0e0;
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
}

.submitButton {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #3e8e41;
}
