.sidebar {
    width: 250px;
    padding: 20px;
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
  }
  
  .sidebar-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .sidebar-section {
    margin-top: 20px;
  }
  
  .sidebar-section h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #555;
  }
  
  .sidebar-list {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-item {
    margin-bottom: 8px;
  }
  
  .sidebar-button {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar-button:hover {
    background-color: #e0e0e0;
  }
  
  .lesson-button {
    color: #007acc;
  }
  
  .quiz-button {
    color: #ff6600;
  }
  