.Layout {}

body.dark-mode {
    background-color: #121212;
    color: #ffffff;
  }
  
  body.light-mode {
    background-color: #ffffff;
    color: #000000;
  }