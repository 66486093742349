.performance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 20px;
    background-color: transparent;
  }
  
  .progress-circle {
    width: 150px; /* Adjust the size of the circle */
    margin-bottom: 20px;
  }
  
  .performance-info {
    text-align: center;
  }
  
  .performance-info p {
    font-size: 14px;
    color: #555;
    margin: 0;
  }
  
  .percentage-box {
    display: inline-block;
    margin-top: 5px;
    padding: 5px 10px;
    background-color: #008772;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
  }
  