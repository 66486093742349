
.fbold{
    font-family: 'Poppins', sans-serif;
    font-weight: 900 !important;
    font-size: 4em;
    line-height: 100.8px;
    text-align: left;
}
.goldfont{
    color: rgb(190, 142, 76);
    font-weight: 900 !important;
}
.boxOne{
    background-color: rgba(255, 255, 255, 0.83) !important;
}
.active{
    background-color: rgb(0, 189, 0);
}

.section{
    width: 100%;
    height: 520px;   
}


