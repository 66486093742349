/* 

.gray{
  color: rgb(165, 165, 165) !important;
}
.navbar {
  background-color: red !important;
  display: block !important;
  visibility: visible !important;
} */


.dropdown-menu {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style: none;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

.dropdown-icon {
  margin-right: 10px;
  font-size: 16px;
  color: #007bff;
}

.dropdown-icon i {
  vertical-align: middle;
}
