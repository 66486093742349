@tailwind base;
@tailwind components;

/* @tailwind utilities; */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
:root {
  font-family: "Poppins", sans-serif;
  --main-color: #008772;
  --light-color: #deeeee;
  --green-color: #023126;
  --second-color: rgb(190, 142, 76);
  --gray: rgb(200, 200, 200);
  --dark-blue:#252641;
  --light-blue:#CAE5FA;
  --light-green:#04BF89;
  
}


* {
  margin: 0;
  padding: 0;
}
body {
  font-family: var(--font-family) !important;
  width: 100vw;
  overflow-x: hidden;
}
Link{
  cursor: pointer;
}
.no-bold {
  font-weight: normal !important;
}
.bg-color {
  background-color: var(--main-color) !important;
}
.bg-greenlight {
  background-color: var(--light-color) !important ;
}
.bg-green {
  background-color: var(--green-color) !important;
}
.bg-gold {
  background-color: var(--second-color) !important;
}
.text-gold {
  color: var(--second-color);
}
.text-color {
  color: var(--main-color) !important;
}
.light-green {
  background-color: #effffd;
}
.textLightGreen {
  color: var(--light-green);
}
.darkblue{
  color: var(--dark-blue) !important;
}
.borderBottomGreen{
  border-bottom: #37E89E 2px solid;
}
.blue-border{
  border: 2px solid var(--light-blue);
}
.blue-bg{
  background-color: var(--light-blue);
}
.bg-darkblue{
  background-color: var(--dark-blue);
}
.main-border {
  border: 2px solid var(--main-color) !important;
}
.gray-border {
  border: 1px solid var(--gray) !important;
}
.small-font {
  font-size: 12px !important;
}
.font-sm {
  font-size: 14px !important;
}
.font-xs {
  font-size: 10px !important;
}
.font-md {
  font-size: 18px !important;
}
.gray-text {
  color: rgb(101, 101, 101) !important;
}
.gray-bg {
  background-color: rgb(233, 233, 233) !important;
}
::placeholder {
  font-size: 12px !important;
  color: rgb(161, 161, 161) !important;
}
a {
  text-decoration: none !important;
  color: black !important;
}
p {
  margin: 0 !important;
}
.shadowbox {
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.1);
}
.index {
  z-index: 99999;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



:root {
  /* Primary Colors */
  --primary-50: #d1faf4;
  --primary-100: #b4fdf2;
  --primary-200: #83fcea;
  --primary-300: #51fbe1;
  --primary-400: #1ffad9;
  --primary-500: #05e0c0;
  --primary-600: #04ae95;
  --primary-700: #037c6a;
  --primary-800: #024b40;
  --primary-900: #011915;

  /* Secondary Colors */
  --secondary-50: #fff2db;
  --secondary-100: #ffdfa8;
  --secondary-200: #ffcc75;
  --secondary-300: #ffb941;
  --secondary-400: #ffa70f;
  --secondary-500: #db8b00;
  --secondary-600: #a86a00;
  --secondary-700: #754a00;
  --secondary-800: #422a00;
  --secondary-900: #0f0a00;

  /* Neutral Colors */
  --neutral-50: #f2f2f2;
  --neutral-100: #e3e3e3;
  --neutral-200: #c9c9c9;
  --neutral-300: #b0b0b0;
  --neutral-400: #969696;
  --neutral-500: #7d7d7d;
  --neutral-600: #636363;
  --neutral-700: #4a4a4a;
  --neutral-800: #303030;
  --neutral-900: #171717;

  /* Pure Colors */
  --pure-white: #ffffff;
  --pure-black: #000000;
}

body {
  font-family: 'Poppins', sans-serif;
}

a.active {
  @apply text-neutral-50 lg:text-primary-700 dark:text-neutral-900 dark:lg:text-primary-700;
}
/* Global RTL Styles in index.css */
body.rtl {
  direction: rtl;
  text-align: right;

}

/* RTL Adjustments for specific elements */
body.rtl .container {
  text-align: right;
}

body.rtl .card {
  text-align: right;
}

body.rtl h2 {
  text-align: right;
}
body.rtl .card p {
  text-align: right;
}

/* body.rtl .ms-10 {
  margin-right: 2.5rem; 
  margin-left: 0;
} */

body.rtl .text-start {
  text-align: right;
}

body.rtl .fa-chevron-right {
  transform: rotate(180deg); /* Flip icon direction for RTL */
}
