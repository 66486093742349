.quiz-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
  }
  
  .loading {
    font-size: 24px;
    color: #333;
  }
  
  .question-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
    padding: 30px;
    text-align: center;
  }
  
  .question-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .question-text {
    font-size: 20px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .answer-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .answer-button {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s ease;
  }
  
  .answer-button.selected {
    background-color: #4a4a4a;
    color: white;
    border-color: #56cfe1;
  }
  
  .answer-button:hover {
    background-color: #292929;
    color:#fff;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .nav-button {
    padding: 10px 20px;
    border: none;
    background-color: #008772;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease;
  }
  
  .nav-button:hover {
    background-color: #023126;
  }
  
  .submit-button {
    background-color: #38b000;
  }
  
  .result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-align: center;
  }
  
  .result-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 8px solid #38b000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #38b000;
    font-weight: bold;
    position: relative;
  }
  
  .result-circle p {
    position: absolute;
    bottom: -25px;
    width: 100%;
    font-size: 18px;
    color: #333;
  }
  