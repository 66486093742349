/* LessonDetails.css */

/* body {
    font-family: Arial, sans-serif;
    background-color: #1e1e2f;
    color: #ffffff;
    margin: 0;
    padding: 0;
  } */
  
  .lesson-details {
    display: flex;
    height: 100vh;
  }
  
  /* Sidebar Styling */
  /* .sidebar {
    width: 250px;
    background-color: #2b2b3f;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar h3 {
    font-size: 18px;
    color: #a0a0b0;
    margin-bottom: 10px;
    border-bottom: 1px solid #414157;
    padding-bottom: 5px;
  }
  
  .sidebar button {
    background-color: #3a3a55;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin: 5px 0;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .sidebar button:hover {
    background-color: #4c4c71;
  } */
  
  /* Lesson Content Styling */
  .lesson-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .lesson-content h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .lesson-content video {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .lesson-content p {
    font-size: 16px;
    color: #c0c0cf;
    line-height: 1.6;
  }
  
  /* Quiz Page Styling */
  .quiz-page {
    padding: 20px;
    text-align: center;
  }
  
  .quiz-page h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }

/* Responsive styles */
@media (max-width: 768px) {
  .lesson-details{
    display: block;
    height: 100%;
  }
  .sidebar{
    width: 100%;
  }
}