.lesson-assign-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
  
  .lesson-title {
    /* text-align: center; */
    margin-bottom: 20px;
  }
  
  .lesson-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .lesson-description{
    flex: 2;
    border: 2px solid #008772;
    border-radius: 8px;
    padding: 20px;
    min-width: 250px;
  }
  .lesson-quiz,
  .lesson-recap,
  .lesson-feedback,
  .lesson-resources {
    flex: 1;
    border: 2px solid #008772;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    min-width: 250px;
  }
  .lesson-resources,
  .lesson-feedback {
    text-align: start;
  }
  
  .quiz-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    border: 4px solid #008772;
    color: #ff9900;
    font-size: 18px;
    font-weight: bold;
  }
  
  .quiz-circle.clickable {
    cursor: pointer;
  }
  
  .recap-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .play-icon {
    width: 50px;
    height: 50px;
  }
  
  @media (max-width: 768px) {
    .lesson-row {
      flex-direction: column;
      gap: 20px;
    }
  }
  